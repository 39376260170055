.alert-bar {
    background-color: $maroon;
    color: $white;
    text-align: center;
    padding: 12px 0;
    position: relative;
    z-index: 200;
    p {
        margin: 0;
    }
    a {
        color: $white;
    }
}

.alert-bar-button {
    text-decoration: underline;
    display: inline-block;
    &:hover, &:focus {
        text-decoration: none;
        img {
            transform: scale(1.3);
        }
    }
    img {
        margin: 0 0 0 8px;
        transition: transform .2s ease-out;
    }
}