body.insights-report {
    h3,
    .h3 {
        font-size: rem-calc(26);
        line-height: 1.2;
    }
    h4,
    .h4 {
        font-size: rem-calc(20);
        font-weight: $heading-bold;
        color: $main-color;
        line-height: 1.25;
        margin: 0 0 22px;
    }
    p,
    ol,
    ul {
        font-size: rem-calc(18);
        line-height: 1.6;
        margin-left: 0;
        li ul {
            li::before {
                border-radius: 0;
            }
        }
    }
    ol {
        list-style-position: inside;
        margin: 0 0 20px;
    }
    .text-sml {
        font-size: rem-calc(14px) !important;
        line-height: 1.75;
    }
    .alt-heading-small {
        font-size: rem-calc(18);
        line-height: 1.5;
        font-weight: $heading-bold;
        margin-bottom: 20px;
    }
}

.insights-split-section {
    margin: 40px 0;
    img {
        margin-bottom: 0;
    }
    .pie-chart {
        .flex-container {
            display: flex;
            flex-direction: column;
        }
        &.reverse {
            .flex-container {
                flex-direction: column-reverse;
            }
        }
        .canvas-container {
            height: 30vh;
            width: 80vw;
            margin: 0 auto 20px;
        }
        .text {
            font-size: rem-calc(14);
            line-height: 1.1;
            text-align: center;
            margin: 0 auto 40px;
            width: 100%;
            .highlight {
                font-size: rem-calc(40);
                font-weight: $main-bold;
                color: $teal;
                display: block;
            }
        }
    }
    .horizontal-chart {
        .canvas-container {
            position: relative;
            height: 30vh;
            width: 84vw;
            &.tall {
                height: 60vh;
            }
        }
    }
}

@include breakpoint(900px) {
    .insights-split-section {
        .grid-row {
            display: grid;
            gap: 1em;
            grid-template-columns: minmax(0, 3fr) auto minmax(0, 3fr);
            .seperator {
                width: 2px;
                background-color: $brand-accent;
            }
        }
        .pie-chart {
            .flex-container {
                align-items: center;
                flex-direction: row;
            }
            &.reverse {
                .flex-container {
                    align-items: center;
                    flex-direction: row;
                }
            }
            .canvas-container {
                height: 20vh;
                width: 20vw;
                position: relative;
            }
            .text {
                margin: 0;
                text-align: left;
                &:first-child {
                    margin-right: 10px;
                }
                &:last-child {
                    margin-left: 10px;
                }
            }
        }
        .horizontal-chart {
            .canvas-container {
                height: 30vh;
                width: 100%;
            }
        }
    }
}

.insights-table-caption {
    text-align: left;
    .subcaption {
        display: block;
        color: $blue-steel;
        font-family: $main-font;
        font-size: rem-calc(18);
        line-height: 1.6;
        font-weight: $main-normal;
    }
}

.insights-table {
    width: 83vw;
    overflow-x: auto;
	margin-bottom: $base-spacing;
    table {
        font-size: rem-calc(18);
        line-height: 1.1;
        margin-bottom: 0;
        tr {
            th {
                font-size: rem-calc(14px);
                padding: 4px 10px;
            }
            td {
                text-align: center;
                border: 1px solid $light-gray;
                &:first-child {
                    text-align: left;
                }
            }
            &:nth-child(2n) td {
                background-color: $white;
            }
            &.spacer {
                td {
                    border: none;
                    padding: 0;
                    height: 10px;
                }
            }
            &.bg-light-gray {
                background-color: $off-white !important;
                th {
                    border: 1px solid $light-gray;
                }
            }
            &.bg-dark-tan {
                td {
                    background-color: $dark-tan;
                    color: $white;
                }
            }
        }
        .grouper-row {
            background-color: $white;
            td {
                border: none;
                font-size: rem-calc(14px);
                font-weight: $main-bold;
                padding: 2px;
            }
        }
        .grouper {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 2%;
                width: 96%;
                border-bottom: 2px solid $gray;
            }
        }
    }
}

.insights-callout {
    background: $light-gray;
    color: $dark-gray;
    padding: 20px;
    margin: 80px 0;
    .heading {
        font-size: rem-calc(26);
        line-height: 1.5;
    }
    p {
        margin-bottom: 0;
    }
}

@include breakpoint(900px) {
    .insights-table {
        width: 100%;
    }
    .insights-callout {
        padding: 30px;
    }
}
