.four-cornerstones-cards {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 40px 30px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
	@include breakpoint(1000px) {
		grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
	}
}

.four-cornerstones-card {
	background: $white;
	border-radius: 10px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
	padding-bottom: 20px;
	overflow: hidden;
	h2 {
		font-size: rem-calc(34);
		color: $white;
		line-height: 1.18;
		padding-top: 30px;
		padding-bottom: 30px;
		margin-bottom: 30px;
		&.header-blue {
			background-color: $brand;
		}
		&.header-green {
			background-color: #16626A;
		}
		&.header-red {
			background-color: $maroon;
		}
		&.header-gray {
			background-color: $blue-steel;
		}
	}
	ul {
		margin: 0;
	}
	> * {
		padding-left: 30px;
		padding-right: 30px;
	}
}