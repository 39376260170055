.image-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 15px;
	margin-bottom: $base-spacing;
	@include breakpoint(large) {
		grid-gap: 30px;
	}
	.grid-item-full {
		grid-column: 1 / -1;
	}
	img {
		margin: 0;
	}
}

.logo-grid {
	margin-bottom: $base-spacing;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 30px;
	align-items: center;
	@include breakpoint(large) {
		grid-gap: 80px;
	}
	> div {
		text-align: center;
	}
}