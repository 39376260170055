/*----------------------------------------------------------*\
   Spacing Sections

   Sections can be used to increase whitespace between
   content sections as needed. The recommended method is
   wrapping the content in a div and applying the class
   with the spacing you want. The -full suffix will apply
   padding to the top and bottom which is well-suited for
   content areas that span the full width of the screen
   and use a background color.

\*----------------------------------------------------------*/

.content-section-full {
	padding-top: $full-section-spacing-small;
	padding-bottom: $full-section-spacing-small - $base-spacing;
	&.no-base {
		padding-bottom: $full-section-spacing-small;
	}
}
.intro-section {
	padding-bottom: $intro-section-spacing-small - $base-spacing;
	&.no-base {
		padding-bottom: $intro-section-spacing-small;
	}
}
.content-section {
	padding-bottom: $content-section-spacing-small - $base-spacing;
	&.no-base {
		padding-bottom: $content-section-spacing-small;
	}
}
.content-section-top {
	padding-top: $content-section-spacing-small;
}
.content-sub-section {
	padding-bottom: $content-sub-section-spacing-small;
	&.no-base {
		padding-bottom: $content-sub-section-spacing-small;
	}
}


.section-seperator-decorator {
	margin-top: 40px;
	&::before {
		content: '';
		width: 100%;
		height: 80px;
		background: url('#{$imgcdn}/seperator_decorator.png?auto=format') repeat-x left top;
		background-size: auto 100%;
		display: block;
		position: absolute;
		top: -40px;
		left: 0;
	}
}

@include breakpoint(large) {
	.content-section-full {
		padding-top: $full-section-spacing;
		padding-bottom: $full-section-spacing - $base-spacing;
		&.no-base {
			padding-bottom: $full-section-spacing;
		}
	}
	.intro-section {
		padding-bottom: $intro-section-spacing - $base-spacing;
		&.no-base {
			padding-bottom: $intro-section-spacing;
		}
	}
	.content-section {
		padding-bottom: $content-section-spacing - $base-spacing;
		&.no-base {
			padding-bottom: $content-section-spacing;
		}
	}
	.content-section-top {
		padding-top: $content-section-spacing - $base-spacing;
	}
	.content-sub-section {
		padding-bottom: $content-sub-section-spacing - $base-spacing;
		&.no-base {
			padding-bottom: $content-sub-section-spacing;
		}
	}
}