.grid-divider-section {
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 30px;
	padding: 40px 0;
	.grid-divider-row {
		display: grid;
		grid-template-columns: 1fr;
	}
}

@include breakpoint(800px) {
	.grid-divider-section {
		background-size: 100% auto;
		padding: 80px 0;
		display: block;
		.grid-divider-row {
			grid-template-columns: 1fr auto 1fr;
			grid-column-gap: 40px;
			margin-bottom: 0;
			.seperator {
				width: 2px;
				background-color: $light-gray;
			}
			.heading, .text {
				padding-top: 40px;
				padding-bottom: 10px;
			}
			&.intro + .grid-divider-row {
				.heading, .text {
					padding-top: 0;
				}
			}
		}
		.heading.align-right {
			text-align: right;
		}
	}
}