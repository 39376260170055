// Media object section with overlap on bottom of media
.media-object-overlap-section {
	background-image: url('/images/textures/square_pattern.svg');
	padding: 40px 0 0;
	margin-bottom: 210px;
	.inner {
		max-width: 600px;
	}
	.media {
		img {
			margin-bottom: -160px;
		}
	}
}

@include breakpoint(1000px) {
	.media-object-overlap-section {
		padding-top: 120px;
		margin-bottom: 190px;
		.inner {
			max-width: 83.75rem;
			display: flex;
			align-items: flex-start;
		}
		.media {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: 50%;
			img {
				margin-bottom: -70px;
			}
		}
		.text {
			padding-right: 30px;
			flex-basis: 50%;
			flex-grow: 1;
			flex-shrink: 1;
		}
	}
}

// Standard media object
.media-object {
	max-width: 400px;
	margin: 0 auto;
}

@include breakpoint(medium) {
	.media-object {
		max-width: 1080px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		.media {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: 413px;
		}
		.text {
			padding-left: 30px;
			order: 1;
			flex-shrink: 1;
			flex-grow: 1;
			flex-basis: 636px;
		}
	}
}