.grid-col-2 {
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 30px;
	@include breakpoint(900) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 30px;
	}
}

.grid-col-3 {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 45px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
	.grid-col-item {
		max-width: 410px;
	}
	.bottom-align-last {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		> *:last-child {
			margin-top: auto;
		}
	}
}

.grid-col-auto {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 45px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	}
}