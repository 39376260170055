/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/
$header-layout-breakpoint: 500px;

.page-header-wrapper {
    will-change: transform;
    position: sticky;
    top: 0;
    z-index: 200;
    .logo-small {
        display: none;
    }
    &.small-header {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        .inner {
            align-items: center;
        }
        .page-header {
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .large-extra-nav {
            display: none;
        }
        .logo-large {
            display: none;
        }
        .logo-small {
            display: flex;
        }
        .large-nav > ul > li {
            padding-top: 16px;
            padding-bottom: 20px;
            &.button-nav {
                margin-bottom: -2px;
            }
        }
        .button-nav {
        }
        .logo {
            padding: 0;
        }
    }
}

@include breakpoint(1090px) {
    .page-header-wrapper {
        &.small-header {
            .page-header {
                padding-top: 0;
                padding-bottom: 0;
            }
            .large-nav > ul > li {
                padding-top: 15px;
                padding-bottom: 20px;
                &.button-nav {
                    margin-bottom: -2px;
                }
            }
        }
    }
}

.page-header {
    background: $white;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(232, 234, 234, 1) 24.32%, rgba(221, 223, 224, 1) 41.8%);
    padding: 10px 0;
    position: relative;
    z-index: 20;
    .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo {
        max-width: 105px;
        margin-right: 20px;
        svg {
            max-width: 100%;
            height: auto;
        }
    }
    img {
        margin: 0;
    }
    .large-navs {
        display: none;
    }
}

.nav-toggle {
    width: 40px;
    height: 40px;
    background-color: $brand;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@include breakpoint(1090px) {
    .page-header {
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(229, 231, 232, 1) 89.55%, rgba(226, 228, 229, 1) 100%);
        padding-top: 20px;
        padding-bottom: 0;
        .inner {
            align-items: flex-end;
        }
        .nav-toggle {
            display: none;
        }
        .large-navs {
            display: block;
        }
        .logo {
            max-width: 150px;
            padding-bottom: 12px;
            img {
                width: auto;
            }
        }
    }
    .offscreen-nav {
        display: none;
    }
}

@include breakpoint(1200px) {
    .page-header {
        .logo {
            max-width: 200px;
        }
    }
}

.body-separator {
    background-color: $brand;
    width: 100%;
    height: 20px;
    position: relative;
    z-index: 3;
    @include breakpoint($header-layout-breakpoint) {
        height: 40px;
    }
}