@font-face {
  font-family: 'Abigail';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/abigail-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/abigail-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Pyke Display';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/pyke-display-bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/pyke-display-bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Pyke Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/pyke-text-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/pyke-text-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Pyke Text';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/pyke-text-bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/pyke-text-bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Visuelt';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/visuelt-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/visuelt-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
