/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/

$imgcdn: 'https://rw-invest.imgix.net';
$base-spacing: 30px;


//Font families
$sans-serif-font: 'Visuelt', Arial, Tahoma, sans-serif;
$serif-font:'Pyke Text', Georgia, Times, Times New Roman, serif;
$heading-font: 'Pyke Display', Times, Times New Roman, serif;
$main-font: $serif-font;
$alt-font: $sans-serif-font;
$rw-font: 'Abigail', Georgia, serif;

//Font weights
$main-normal: 400;
$main-bold: 700;
$alt-normal: 400;
$heading-bold: 700;

//Font sizes
$font-sizes: (
  small: (rem-calc(14), 1.29),
  default: (rem-calc(18), 1.44),
  large: (rem-calc(24), 1.25),
  small-desktop: (rem-calc(14), 1.29),
  default-desktop: (rem-calc(24), 1.33),
  large-desktop: (rem-calc(32), 1.25)
);


//Styleguide colors
$brand:				 #082039;
$brand-accent: #C2C5C7;
$maroon:       #6E173A;
$teal:   			 #40909A;
$purple:		   #361F2E;
$blue-steel:	 #353F49;
$dark-tan:	   #65665E;
$gray:         #9FA1A1;
$dark-gray:		 #515457;
$light-gray:	 #DADCDD;
$white:        #FFFFFF;
$off-white:    #F2F2F2;
$black:        #000000;

$colors: (
	'brand':			 		 $brand,
	'brand-accent':		 $brand-accent,
	'maroon':          $maroon,
	'teal':      			 $teal,
	'purple':       	 $purple,
	'blue-steel':      $blue-steel,
	'dark-tan':        $dark-tan,
	'gray':            $gray,
	'dark-gray':       $dark-gray,
	'light-gray':      $light-gray,
	'white':           $white,
	'off-white':       $off-white,
	'black':           $black
);

//Gradients, for use on backgrounds only
$gradients: (
	'black-top-down': linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)
);

//Transparent background colors
$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.8);

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $blue-steel;

//Link colors
$link-base: $maroon;
$link-active: $maroon;

//Social media brand colors
$social: (
	'twitter': 		 #00aced,
	'facebook': 	 #3b5998,
	'googleplus': 	 #dd4b39,
	'pinterest': 	 #cb2027,
	'linkedin': 	 #007bb6,
	'youtube': 		 #bb0000,
	'vimeo': 		 #aad450,
	'instagram': 	 #517fa4,
	'quora': 		 #a82400,
	'foursquare': 	 #0072b1,
	'stumbleupon': 	 #EB4823,
	'soundcloud': 	 #ff3a00
);

//Shadows
$text-shadow-brand: 0 1px 1px rgba(0, 109, 121, 0.5);
$text-shadow: 1px 0 1px rgba(0,0,0,0.65);
$text-shadow-light: 1px 1px 1px rgb(255,255,255);
$box-shadow-subtle: 0 0 4px rgba(0, 0, 0, 0.2);
$box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
$box-shadow-heavy: 0 0 70px rgba(0, 0, 0, 0.2);

//Rounded corners
$border-round: 1000px;
$border-radius: 17px;

//Section spacing
$content-section-spacing-small: 	40px;
$content-sub-section-spacing-small: 20px;
$full-section-spacing-small: 		$content-section-spacing-small;
$intro-section-spacing-small: 		48px;
$content-section-spacing: 			80px;
$content-sub-section-spacing: 		40px;
$full-section-spacing: 				$content-section-spacing;
$intro-section-spacing: 			54px;

//Hero backgrounds
$hero-bg: (
	'home-orange': test,
	'farmers-field': farmers_field,
	'construction-workers-talking': construction_workers_talking,
	'penitentiary-toilet-row': penitentiary_toilet_row,
	'park-fence-line': park_fence_line,
	'trailer-corn-field': trailer_corn_field,
	'downtown-boise': downtown_boise,
	'man-roofing': man_roofing,
	'concert-crowd': concert_crowd,
	'happy-married-couple': happy_married_couple,
	'portapros-truck': portapros_truck,
	'basketball-dunk': basketball_dunk,
	'gray-toilet-row-perspective': gray_toilet_row_perspective,
	'smokey-mountain': smokey_mountain,
	'ski-team': ski_team,
	'toilet-hand-wash-box': toilet_hand_wash_box,
	'exec-restroom-trailer-white': exec_restroom_trailer_white,
	'ag-trailer-toilets': ag_trailer_toilets,
	'travis-leaning': travis_leaning,
	'grove-hand-wash-stations': 'grove_hand_wash_stations'
);

$hero-bg-color: (
	'home-orange': #ff9900,
	'farmers-field': #d7d8dd,
	'construction-workers-talking': #abb4bb,
	'penitentiary-toilet-row': #555c66,
	'park-fence-line': #17675a,
	'trailer-corn-field': #909a65,
	'downtown-boise': #fee2ba,
	'man-roofing': #bed9ee,
	'concert-crowd': #070709,
	'happy-married-couple': #ece59d,
	'portapros-truck': #485e2f,
	'basketball-dunk': #c5c8cd,
	'gray-toilet-row-perspective': #b4a892,
	'smokey-mountain': #b4a892,
	'ski-team': #2c3f5d,
	'exec-restroom-trailer-white': #2c3f5d,
	'ag-trailer-toilets': #d8c8ae,
	'travis-leaning': #d8c8ae
);