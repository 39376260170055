/*----------------------------------------------------------*\
   Blockquote

   Blockquote styling with large quote image. Background
   color can be overriden with the background (and gradient)
   classes listed on the styleguide.

\*----------------------------------------------------------*/
blockquote {
   background: url('/images/icons/quote.svg') no-repeat left top;
   background-size: 80px 48px;
   color: $gray;
   padding: 22px 0 0 20px;
   margin: 60px 0;
   p {
      font-size: rem-calc(20);
      font-style: italic;
      margin-bottom: 20px;
   }
   cite {
      font-family: $alt-font;
      font-style: normal;
   }
}

@include breakpoint(large) {
   blockquote {
      background-size: 185px 114px;
      padding-top: 24px;
      padding-left: 40px;
      p {
         font-size: rem-calc(42);
         line-height: 1.29;
      }
      cite {
         font-size: rem-calc(24);
         line-height: 1.33;
      }
   }
}