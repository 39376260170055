/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    background: $white;
    padding: $content-section-spacing-small 0 ($content-section-spacing-small - $base-spacing);
}

@include breakpoint(large) {
	.page-content {
		padding: $content-section-spacing 0 ($content-section-spacing - $base-spacing);
	}
}