/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/
$box-shadow-min-width: 10px;
$box-shadow-max-width: 400px;

.button,
form input[type="submit"] {
	max-width: 400px;
	background: none;
	font-family: $alt-font;
	font-weight: $alt-normal;
	font-size: rem-calc(18);
	color: $main-color;
	line-height: 1.11;
	padding: 17px 24px 13px;
	margin-bottom: $base-spacing;
	border: 0;
	overflow: hidden;
	display: inline-block;
	transition: color .2s;
	position: relative;
	> span {
		position: relative;
		z-index: 10;
	}
	&:hover,
	&:focus {
		color: $white;
		text-decoration: none;
		cursor: pointer;
		transition: color .4s;
		&::after {
			transform: translateX(100%);
			transition: transform .2s ease-out;
		}
	}
	&::after {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		background: $maroon;
		right: 100%;
		top: 0;
		transform: translateX(10px);
		transition: transform .2s;
	}
	&.post-icon {
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		.icon {
			margin-left: 15px;
		}
		.icon-arrow-right {
			margin-top: -3px;
		}
	}
	&.gray::after {
		background-color: $brand-accent;
	}
	&.blue::after {
		background-color: $blue-steel;
	}
	&.light {
		color: $white;
		&:hover,
		&:focus {
			color: $blue-steel;
		}
		&::after {
			background-color: $brand-accent;
		}
	}
}

.button.small {
	font-size: rem-calc(14);
	padding: 6px 13px 10px;
}
.button.large {
	font-size: rem-calc(24);
	padding: 17px 23px 15px;
}
.button.short {
	padding-top: 7px;
	padding-bottom: 9px;
}
.button.tiny {
	font-size: rem-calc(12);
	line-height: 1;
	padding: 6px;
}

.button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 50px;
}