.hero-simple-text {
	background-color: $brand;
	background-image: url('/images/textures/square_pattern.svg');
	min-height: 240px;
	display: flex;
	align-items: center;
	justify-content: center;
	h1 {
		text-align: center;
		color: $white;
        line-height: 1.2;
		margin: 0;
        @include breakpoint(large) {
            font-size: rem-calc(64);
        }
	}
}