.resource-download {
	text-align: center;
	h1 {
		font-size: rem-calc(32);
		line-height: 1.2;
		margin-bottom: 10px;
	}
	img {
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
	}
}

@include breakpoint(800px) {
	.resource-download h1 {
		font-size: rem-calc(44);
	}
}