.secondary-nav {
	width: 100%;
	background: $white;
	font-family: $alt-font;
	font-weight: $alt-normal;
	color: $main-color;
	padding: 0 0 50px;
	position: absolute;
	bottom: 0;
	z-index: 1;
	transition: transform .3s;
	.extra-nav {
		font-size: rem-calc(13);
		padding: 20px;
		margin: 20px 0;
		a {
			color: $main-color;
		}
		li {
			margin-bottom: 15px;
		}
	}
}

.large-nav {
	font-family: $alt-font;
	text-transform: uppercase;
	position: relative;
	a, .dropdown-label {
		color: $brand;
		line-height: 1.25;
		display: inline-block;
	}
	.dropdown {
		width: 320px;
		background: $brand;
		text-transform: none;
		padding: 15px 20px;
		position: absolute;
		top: 100%;
		left: -25px;
		visibility: visible;
		opacity: 0;
		z-index: 40;
		transition: opacity .3s, visibility .3s;;
		a {
			color: $white;
			padding: 5px 10px;
			display: block;
			transition: color .3s, background .3s;
			&:hover, &:focus, &.active  {
				background-color: $light-gray;
				color: $brand;
				text-decoration: none;
			}
			&.active {
				background-image: url('/images/icons/right_arrow.svg');
				background-position: 10px 8px;
				background-repeat: no-repeat;
				padding-left: 40px;
			}
		}
		li {
			padding: 0;
		}
	}
	.dropdown-label {
		cursor: default;
		.icon {
			margin-right: 5px;
		}
		.icon svg {
			transition: transform .2s;
		}
	}
	> ul {
		display: flex;
		align-items: flex-end;
		> li {
			font-size: rem-calc(16);
			line-height: 1.25;
			margin-left: 25px;
			padding-bottom: 38px;
			@include breakpoint(1200) {
				margin-left: 30px;
			}
            &:first-child {
                margin-left: 0;
            }
			&:hover, &:focus {
				.dropdown-label {
					border-bottom-color: $brand;
					text-decoration: none;
				}
			}
			> a, .dropdown-label {
				padding-bottom: 2px;
				border-bottom: solid 2px transparent;
				transition: border .3s;
				&:hover, &:focus, &.active {
					border-bottom-color: $brand;
					text-decoration: none;
				}
			}
		}
	}
	.has-dropdown {
		overflow: hidden;
		&.active,
		&.selected {
			.dropdown-label {
				border-bottom-color: $brand;
			}
		}
	}
	.has-dropdown:hover,
	.has-dropdown:focus {
		overflow: visible;
		.dropdown-label .icon svg {
			transform: rotate(90deg);
		}
		.dropdown {
			visibility: visible;
			opacity: 1;
		}
	}
    .button-nav {
        padding-bottom: 33px;
        flex-shrink: 0;
        a {
            background: $maroon;
            font-weight: $main-bold;
            color: $white;
            padding: 11px 15px 9px;
            border-bottom: 0;
            transition: background-color .3s;
            &:hover, &:focus {
                background: $brand;
            }
        }
    }
}


.large-extra-nav {
	font-family: $alt-font;
	font-size: rem-calc(14);
	line-height: 1;
	margin-bottom: 48px;
	ul {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	li {
		padding-left: 20px;
		margin-left: 20px;
		position: relative;
		&::before {
			content: '';
			width: 2px;
			height: 12px;
			background: $brand;
			border-radius: 0;
			display: block;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
		&:first-child {
			padding-left: 0;
			margin-left: 0;
			&::before {
				display: none;
			}
		}
	}
	a {
		color: $brand;
	}
}