.rw-section {
	background: url('/images/rw_watermark.svg') no-repeat center top $white;
	background-size: 1000px auto;
	padding: 40px 0;
	.grid-row {
		display: grid;
		grid-template-columns: 1fr;
	}
}

@include breakpoint(900px) {
	.rw-section {
		background-size: 100% auto;
		padding: 80px 0;
		.grid-row {
			grid-template-columns: 1fr auto 1fr;
			grid-column-gap: 40px;
			.seperator {
				width: 2px;
				background-color: $brand-accent;
			}
			.headings, .text {
				padding-top: 40px;
				padding-bottom: 10px;
			}
		}
	}
}

.rw-mega-section {
	background: url('/images/rw_watermark.svg') no-repeat center top $white;
	background-size: 1000px auto;
	@include breakpoint(900px) {
		background-size: 100% auto;
	}
	.rw-section.no-bg {
		background: none;
	}
}

// RW split section with no bg mark
.rw-section.no-bg {
	background-image: none;
}

// RW tabs
.rw-slider-section {
	background-color: #8a8b8f;
	background-image: url('#{$imgcdn}/textures/four_corners.png?auto=format&w=1000&q=70'), linear-gradient(270deg,#878787,#8a8b8f);
	background-repeat: repeat;
	background-position: center;
	position: relative;
	padding: 40px 0;
	.three-w-slider-trigger {
		cursor: pointer;
		path {
			transition: fill .3s;
		}
		&:hover path, &:focus path, &.selected path {
			fill: $brand;
		}
		&.selected .three-w-slider-arrow-down {
			display: block;
		}
	}
	.three-w-slider-arrow-down,
	.three-w-slider-arrow-right {
		display: none;
	}
	.three-w-tab-content {
		display: grid;
	}
	.three-w-tab-content-item {
		color: $white;
		visibility: hidden;
		grid-column-start: 1;
		grid-row-start: 1;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		&.active {
			visibility: visible;
		}
		p {
			font-size: rem-calc(22);
			line-height: 1.27;
			margin: 0;
		}
	}
	h2 {
		color: $brand;
		text-align: center;
		margin-bottom: 35px;
	}
	.headings svg {
		max-width: 400px;
		width: 100%;
		height: auto;

	}
	.grid-row {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 40px;
		.seperator {
			display: none;
		}
	}
}

@include breakpoint(800px) {
	.rw-slider-section {
		padding: 60px 0 80px;
		.grid-row {
			grid-template-columns: 1fr auto 1fr;
			grid-gap: 40px;
			align-items: center;
			.seperator {
				width: 2px;
				height: 100%;
				background-color: $brand;
				display: block;
			}
			.headings, .text {
				padding-top: 40px;
				padding-bottom: 40px;
			}
		}
		.three-w-slider-trigger.selected {
			.three-w-slider-arrow-down {
				display: none;
			}
			.three-w-slider-arrow-right {
				display: block;
			}
		}
	}
}

@include breakpoint(975px) {
	.rw-slider-section .headings svg {
		max-width: 460px;
		width: 100%;
	}
}

@include breakpoint(1100px) {
	.rw-slider-section {
		.three-w-tab-content-item {
			p {
				font-size: rem-calc(32);
				line-height: 1.25;
			}
		}
		.grid-row {
			grid-gap: 80px;
		}
	}
}