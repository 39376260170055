// Hero

.home-hero {
	.main-hero {
		background: url('#{$imgcdn}/hero/home/father_son_ocean.jpg?auto=format&fit=crop&w=700&h=700') no-repeat center top #20233b;
		background-size: cover;
        color: $white;
        padding: 30px 0;
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('#{$imgcdn}/hero/home/father_son_ocean.jpg?auto=format&fit=crop&w=700&h=700&dpr=2&q=20');
		}
        h1 {
            font-size: clamp(1.875rem, 3.191vw + 1.157rem, 3.75rem);
            color: $white;
            line-height: 1;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 20px;
        }
        .inner {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20px;
            > * {
                max-width: 560px;
            }
        }
        .main-hero-video {
            img {
                margin: 0;
            }
            .button-video-modal {
                cursor: pointer;
                position: relative;
                .play-button {
                    color: $maroon;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: color .3s;
                }
                &:hover .play-button, &:focus-visible .play-button {
                    color: $brand;
                }
            }
        }
        .main-hero-content {
            > *:last-child {
                margin-bottom: 0;
            }
        }
	}
	.sub-hero {
		background-image: url('/images/textures/square_pattern.svg');
		background-color: #011D38;
		background-position: left top;
		background-repeat: repeat;
		background-attachment:fixed;
		color: $white;
		padding: 60px 0 60px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		&::after {
			content: '';
			width: 1530px;
			height: 437px;
			background: url('/images/textures/blue_mountains.svg') no-repeat left bottom;
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 1;
		}
		.inner {
			position: relative;
			z-index: 2;
		}
    	h2 {
    		font-size: clamp(1.75rem, 3.191vw + 1.032rem, 3.625rem);
    		color: $light-gray;
            line-height: clamp(2.328rem, 2.826vw + 1.692rem, 3.988rem);
    	}
	}
	.button {
		margin-bottom: 0;
	}
}

.home-hero .home-hero-video {
    background: url('#{$imgcdn}/hero/home/bg_video_preview.jpg?auto=format&w=600') no-repeat center #132e46;
    background-size: cover;
    position: relative;
    @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
    only screen and (-o-min-device-pixel-ratio: 13/10),
    only screen and (min-resolution: 120dpi) {
        background-image: url('#{$imgcdn}/hero/home/bg_video_preview.jpg?auto=format&w=600&dpr=2&q=20');
    }
    .autoplay-preview {
        position: relative;
        z-index: 50;
    }
    .home-video-play {
        width: 100%;
        max-width: 300px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 60;
        transform: translate(-50%, -50%);
        h1 {
            font-size: rem-calc(30);
            color: $white;
            line-height: 1.16;
            text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7);
            margin-bottom: 10px;
        }
        .play-button {
            color: $white;
            &:hover {
                cursor: pointer;
            }
            &:hover, &:focus {
                color: $brand;
                path {
                    fill: $white;
                }
            }
            svg {
                max-width: 48px;
                height: auto;
                path {
                    transition: fill .3s;
                }
            }
        }
    }
    .home-video-full {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -1;
        &.visible {
            opacity: 1;
            z-index: 70;
        }
    }

}

@include breakpoint(700px) {
	.home-hero {
		.main-hero {
			background-image: url('#{$imgcdn}//hero/home/father_son_ocean.jpg?auto=format&w=4000&q=40');
			height: 700px;
            padding: 50px 0;
			display: flex;
			justify-content: center;
			@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
			only screen and (-o-min-device-pixel-ratio: 13/10),
			only screen and (min-resolution: 120dpi) {
				background-image: url('#{$imgcdn}//hero/home/father_son_ocean.jpg?auto=format&w=4000&dpr=2&q=20');
			}
			.button {
				margin: 0;
			}
		}
		.sub-hero {
			padding-top: 100px;
            padding-bottom: 100px;
			.logo-3-w {
				display: none;
			}
		}
	}

    .home-hero .home-hero-video {
        background-image: url('#{$imgcdn}/hero/home/bg_video_preview.jpg?auto=format&w=1200');
        @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
        only screen and (-o-min-device-pixel-ratio: 13/10),
        only screen and (min-resolution: 120dpi) {
            background-image: url('#{$imgcdn}/hero/home/bg_video_preview.jpg?auto=format&w=1200&dpr=2&q=20');
        }
        .home-video-play {
            max-width: 580px;
            h1 {
                font-size: rem-calc(60);
                line-height: 1.1;
                margin-bottom: 21px;
            }
            .play-button svg {
                max-width: 64px;
            }
        }
    }

}

@include breakpoint(large) {
	.home-hero {
        .main-hero {
            align-items: center;
            h1 {
                margin-bottom: 20px;
            }
            .inner {
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
                gap: 60px;
                > * {
                    max-width: 100%;
                }
            }
        }
        .main-hero-content {
            flex-basis: 605px;
            .button {
                margin-top: 20px;
            }
        }
        .main-hero-video {
            flex-basis: 640px;
            order: 1;
        }
    }
}

@include breakpoint(1200px) {
    .home-hero .home-hero-video {
        background-image: url('#{$imgcdn}/hero/home/bg_video_preview.jpg?auto=format&w=2000');
        @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
        only screen and (-o-min-device-pixel-ratio: 13/10),
        only screen and (min-resolution: 120dpi) {
            background-image: url('#{$imgcdn}/hero/home/bg_video_preview.jpg?auto=format&w=2000&dpr=2&q=20');
        }
    }
}

@include breakpoint(1400px) {
	.home-hero {
		.sub-hero::after {
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

// RW tabs
.rw-topic-tabs-section {
	padding: 40px 0;
	overflow: hidden;
	.three-w-slider-trigger {
		cursor: pointer;
		path {
			transition: fill .3s;
		}
		&:hover path, &:focus path, &.selected path {
			fill: $brand;
		}
	}
	.rw-topic-tab-content {
		position: relative;
	}
	.rw-topic-tabs {
		font-family: $heading-font;
		font-size: rem-calc(24);
		font-weight: $heading-bold;
		font-style: italic;
		line-height: 1;
		a {
			line-height: 1;
			padding: 8px 13px;
			margin-bottom: 3px;
			display: block;
			transition: background-color .2s, color .2s;
			&:hover, &:focus, &.selected {
				background-color: $light-gray;
				color: $blue-steel;
				text-decoration: none;
			}
			&.selected {
				padding-left: 43px;
				position: relative;
				&::before {
					content: '';
					background: url('/images/icons/right_arrow.svg') no-repeat left top;
					width: 20px;
					height: 10px;
					margin-right: 10px;
					display: inline-block;
					position: absolute;
					left: 13px;
					top: 15px;
				}
			}
		}
		ul {
			margin: 0;
			li {
				margin: 0;
				padding: 0;
			}
			li::before {
				display: none;
			}
		}
	}
	.rw-topic-tab-content-item {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		&.active {
			opacity: 1;
			visibility: visible;
			position: relative;
		}
	}
	h2 {
		color: $brand;
		text-align: center;
		margin-bottom: 35px;
	}
	.headings svg {
		max-width: 100%;
	}
	.grid-row {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 40px;
		.seperator {
			display: none;
		}
	}
}

@include breakpoint(800px) {
	.rw-topic-tabs-section {
		padding: 60px 0 80px;
		.grid-row {
			grid-template-columns: 1fr auto 1fr;
			grid-gap: 40px;
			align-items: flex-start;
			.seperator {
				width: 2px;
				height: 100%;
				background-color: $light-gray;
				display: block;
			}
			.rw-topic-tabs, .text {
				padding-top: 40px;
				padding-bottom: 40px;
			}
		}
		.text {
			order: 0;
		}
		.seperator {
			order: 1;
		}
		.rw-topic-tabs {
			order: 2;
		}
		.button {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(975px) {
	.rw-topic-tabs-section .headings svg {
		max-width: 460px;
		width: 100%;
	}
}

@include breakpoint(1100px) {
	.rw-topic-tabs-section {
		.rw-topic-tabs {
			a {
				font-size: rem-calc(34);
				padding-top: 13px;
				padding-bottom: 13px;
				&.selected::before {
					top: 22px;
				}
			}
		}
		.grid-row {
			grid-gap: 80px;
		}
	}
}

// Fade in text animation
@include breakpoint(900px) {
    .fade-in-text {
    	opacity: 0;
    	transition: 2s;
    	&.visible {
    		opacity: 1;
    	}
    }
}