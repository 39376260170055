.team-bio {
	margin-bottom: 80px;
	.title {
		font-size: rem-calc(28);
		font-weight: $main-bold;
		font-style: italic;
		color: $teal;
		line-height: 1.14;
	}
	.image-grid {
		max-width: 630px;
	}
	.large-headshot {
		display: none;
	}
	.large-headshot, .small-headshot {
		img {
			border: solid 2px $light-gray;
		}
	}
}

.team-bio-contact-options {
	font-family: $alt-font;
	margin-bottom: 40px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	.seperator {
		color: $light-gray;
		padding-left: 20px;
		padding-right: 20px;
		img {
			margin: 0;
		}
	}
	.social svg {
		max-height: 25px;
	}
}

.back-to-team-button {
	text-align: center;
}

@include breakpoint(large) {
	.team-bio {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 60px;
		.bio-images {
			order: 1;
		}
		.bio-text {
			order: 2;
		}
		.large-headshot {
			display: block;
		}
		.small-headshot {
			display: none;
		}
	}

	.team-bio-contact-options {
		margin-bottom: 80px;
		.social svg {
			max-height: 40px;
		}
	}
}

// Bio cards
.bio-cards {
	margin-bottom: $base-spacing;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
	@include breakpoint(350px) {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	}
	@include breakpoint(large) {
		grid-gap: 80px 30px;
	}
}

.bio-card {
	max-width: 303px;
	margin: 0 auto;
	.headshot {
		margin-bottom: 10px;
        border: 2px solid $light-gray;
		a {
			display: block;
			position: relative;
			overflow: hidden;
			&:hover, &:focus {
				.action {
					transform: translateX(0);
				}
			}
		}
		img {
			margin: 0;
		}
		.action {
			background: rgba($white, .8);
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			transform: translateX(-100%);
			transition: transform .2s ease-in;
		}
		.button {
			transition: none;
			margin-bottom: 0;
			&:hover, &:focus {
				color: $brand;
				&::after {
					transform: translateX(10px);
				}
			}
		}
	}
	.name {
		font-size: rem-calc(22);
		font-family: $alt-font;
		text-align: center;
	}
	.job-title {
		font-size: .9em;
		color: #3a8089; // Darker than $teal due to accessibility contrast
		display: block;
	}
}