/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/

.page-footer {
    background: linear-gradient(180.7deg, rgba(50, 62, 72, 1) 0%, rgba(121, 129, 135, 1) 48.65%, rgba(192, 196, 199, 1) 100%);
    @include breakpoint(large) {
        background: linear-gradient(90deg, rgba(53, 63, 74, 1) 26.77%, rgba(64, 73, 84, 1) 37.22%, rgba(92, 101, 109, 1) 55.41%, rgba(138, 145, 150, 1) 79.01%, rgba(185, 190, 192, 1) 100%);
    }
    background-color: #33393D;
    font-family: $alt-font;
    font-size: rem-calc(14);
    font-weight: $alt-normal;
    color: $white;
    padding: 40px 0 20px;
    a {
        color: $white;
    }
    h2 {
        font-family: $alt-font;
        font-size: rem-calc(24);
        font-weight: $alt-normal;
        color: $white;
        line-height: 1.33;
        margin-bottom: 10px;

    }
    .main-footer {
        padding-bottom: 10px;
        .contact-options {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            a {
                display: inline-block;
            }
        }
        .icons a {
            margin-right: 20px;
        }
        .logos > * {
            margin-right: 52px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@include breakpoint(900px) {
    .page-footer {
        font-size: rem-calc(16);
        .main-footer {
            padding: 10px 0 40px;
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            grid-gap: 20px;
            align-items: center;
            .divider {
                background-color: $brand-accent;
                width: 1px;
                height: 100%;
                align-self: stretch;
            }
            .icons img {
                width: auto;
            }
        }
    }
}

@include breakpoint(1150px) {
    .page-footer {
        padding: 80px 60px;
        .main-footer {
            grid-gap: 50px;
            .logos {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                grid-column-gap: 80px;
            }
            .logos img {
                width: auto;
                margin-bottom: 0;
            }
        }
    }
}