.form-section {
	background: #43273A;
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	color: $white;
	position: relative;
	padding-top: 80px;
	padding-bottom: 80px - $base-spacing;
	h2 {
		color: $white;
	}
	label {
		font-size: rem-calc(24);
		color: $white;
	}
	.intro {
		max-width: 1000px;
		text-align: center;
		padding-bottom: 80px - $base-spacing;
		margin: 0 auto;
	}
	blockquote {
		max-width: 1000px;
		background: none;
		padding: 0;
		margin: 0 auto $base-spacing;
		p {
			font-size: rem-calc(18);
			font-style: normal;
			color: $white;
			line-height: 1.44;
		}
		cite {
			font-size: rem-calc(18);
			color: $white;
			line-height: 1.44;
		}
	}
}

@include breakpoint(large) {
	.form-section {
		blockquote p, blockquote cite {
			font-size: rem-calc(24);
			line-height: 1.33;
		}
	}
}

.form-section.section-seperator-decorator {
	.intro {
		margin-top: 40px;
	}
}