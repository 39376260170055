.resource-cards {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 50px 30px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
	}
}

.resource-card {
	background: $white;
	max-width: 413px;
	margin: 0 auto;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
	position: relative;
	display: flex;
	flex-direction: column;
	.publish-date {
		background: $white;
		font-family: $alt-font;
		font-size: rem-calc(18);
		line-height: 1;
		padding: 10px;
		box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.08);
		display: inline-block;
		position: absolute;
		top: 0;
		right: 0;
	}
	.text {
		flex-grow: 1;
		padding: 30px;
		display: flex;
		flex-wrap: wrap;
	}
	.button {
		margin-top: auto;
		margin-bottom: 0;
	}
	h2 {
		font-size: rem-calc(24);
		font-weight: $alt-normal;
		line-height: 1.33;
		margin-bottom: 20px;
	}
	img {
		margin: 0;
	}
}

// Highlight cards
.highlight-cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    @include breakpoint(400px) {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}

.highlight-card {
    max-width: 450px;
    margin: 0 auto;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    .heading {
        background-color: $brand;
        font-size: rem-calc(24);
        font-weight: $heading-bold;
        font-style: italic;
        color: $white;
        line-height: 1.25;
        padding: 30px;
        margin: 0;
    }
    .content {
        font-size: rem-calc(20);
        padding: 30px;
        > *:last-child {
            margin-bottom: 0;
        }
    }
    &.maroon .heading {
        background-color: $maroon;
    }
    &.teal .heading {
        background-color: $teal;
    }
}

@include breakpoint(large) {
    .highlight-card {
        .heading {
            font-size: rem-calc(34);
            line-height: 1.18;
        }
    }
}