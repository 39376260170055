.image-bg-container {
	background: url('/images/textures/square_pattern.svg') left top $white;
	padding-top: 30px;
	margin-bottom: 40px;
	text-align: center;
	@include breakpoint(large) {
		padding-top: 80px;
		margin-bottom: 80px;
	}
	img {
		margin: 0;
	}
}