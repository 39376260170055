body.offscreen-nav-visible {
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background: $black;
		opacity: .75;
		z-index: 1500;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.offscreen-nav {
	width: 100%;
	max-width: 450px;
	height: 100%;
    background-color: $white;
	box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.15);
	position: fixed;
	top: 0;
	right: 0;
	z-index: 250;
	transform: translateX(100%);
	transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	visibility: hidden;
	overflow: scroll;
	&.visible {
		z-index: 2100;
		visibility: visible;
		transform: translateX(0);
		transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	}
	a, button {
		color: $white;
		border-bottom: 0;
		cursor: pointer;
		transition: color .3s;
		&:hover,
		&:focus-visible {
			color: $brand-accent;
		}
	}
	.has-dropdown {
		display: flex;
		align-items: start;
		button {
			width: 100%;
			text-align: left;
			display: block;
		}
        .icon {
            margin-right: 5px;
            transition: transform .2s;
            svg {
                width: 6px;
                height: auto;
            }
        }
    }
    .top-level {
		li {
			font-size: rem-calc(22);
			font-weight: $main-normal;
			border-top: solid 1px $light-gray;
			margin-bottom: 0;
            button,
            a.nav-text {
                width: 100%;
                padding: 20px;
            }
            &.selected,
            &.active,
            &:hover,
            &:focus {
                background-color: #f3f3f3;
            }
            &.active,
            &:hover,
            &:focus {
                .icon {
                    transform: rotate(90deg);
                }
            }
			&.nav-button {
				border-top: none;
                padding: 0;
				a {
                    display: block;
                    padding: 22px 20px 18px;
                    background-color: $maroon;
                    font-weight: $main-bold;
                    color: $white;
                    transition: background-color .3s;
                    &:hover, &:focus {
                        background: $brand;
                        text-decoration: none;
                    }
                }
			}
			&.has-children.active {
                background-color: #f3f3f3;
				transition: background-color .3s;
			}
			.has-children:focus-visible {
				outline: solid 1px blue;
			}
            &:first-child {
                border-top: none;
            }
		}
		.nav-text {
			display: inline-block;
			font-family: $sans-serif-font;
			font-size: rem-calc(20);
			font-weight: $main-normal;
            color: $blue-steel;
            line-height: 1.25;
            &:hover,
            &:focus {
                text-decoration: none;
            }
		}
	}
	.second-level {
		li {
			font-size: rem-calc(20);
			line-height: inherit;
			padding: 0;
			border-top: none;
            margin: 0 20px;
            &:last-child {
                padding-bottom: 10px;
            }
		}
		a {
			color: $brand;
            font-family: $sans-serif-font;
            display: block;
            padding: 10px;
            font-size: 1rem;
            transition: background-color .2s;
			&.active,
            &:focus,
			&:hover {
				background-color: $light-gray;
                text-decoration: none;
			}
		}
	}
    .extra-nav {
		font-size: rem-calc(13);
        font-family: $sans-serif-font;
		padding: 20px;
		margin: 20px 0;
		a {
			color: $main-color;
		}
		li {
			margin-bottom: 15px;
		}
	}
}

.offscreen-nav-header {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
    background-image: linear-gradient(90deg,#fff,#e8eaea 24.32%,#dddfe0 41.8%);
    padding: 20px;
    img {
		margin: 0;
	}
}